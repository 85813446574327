@use '@bolt/core' as core;

bolt-connection {
  display: block;
  @include core.bolt-ie11-only {
    width: 250px;
  }
}

.c-bolt-connection {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  &__main-image {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
