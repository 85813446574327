@use 'sass:math';

$header-text-size: 1rem;
$header-text-line-height: 1.65;
$nav-circle-size: 12px;
$nav-circle-size--active: 14px;
$nav-circle-border-size: 2px;

@mixin bolt-micro-journeys-nav-dot($active: false) {
  @if $active {
    width: $nav-circle-size--active;
    min-width: $nav-circle-size--active;
    height: $nav-circle-size--active;
    // Center the dot vertical to the first line of text.
    margin-top: calc(
      (
          (#{$header-text-line-height} * #{$header-text-size}) -
            (#{$nav-circle-size--active} + (#{$nav-circle-border-size} * 2))
        ) /
        2
    );
    margin-left: - math.div($nav-circle-size--active, 2) - $nav-circle-border-size;
    border: $nav-circle-border-size solid var(--m-bolt-primary);
    background-color: var(--m-bolt-bg);
  } @else {
    content: '';
    display: inline-block;
    align-self: flex-start;
    box-sizing: content-box;
    z-index: 1;
    width: $nav-circle-size;
    min-width: $nav-circle-size;
    height: $nav-circle-size;
    // Center the dot vertical to the first line of text.
    margin-top: calc(
      (
          (#{$header-text-line-height} * #{$header-text-size}) -
            #{$nav-circle-size}
        ) /
        2
    );
    margin-right: var(--bolt-spacing-x-small);
    margin-left: - math.div($nav-circle-size, 2);
    border-radius: 50%;
    background-color: var(--bolt-color-navy-light);
  }
}

@mixin bolt-micro-journeys-nav-line() {
  $top: math.div($header-text-size * $header-text-line-height, 2);
  $line-width: 1px;
  content: '';
  display: block;
  position: absolute;
  top: $top;
  left: - math.div($line-width, 2);
  width: $line-width;
  height: 100%;
  background-color: var(--bolt-color-navy-light);
}

@mixin bolt-micro-journeys-nav-title($active: false) {
  @if $active {
    font-weight: bolder;
    color: var(--m-bolt-primary);
    cursor: default;
  } @else {
    display: flex;
    align-items: center;
    padding-bottom: 1.65rem;
    font-size: $header-text-size;
    line-height: $header-text-line-height;
    transition: color 0.5s ease, transform 0.5s ease;

    &:hover {
      cursor: pointer;
    }
  }
}

@mixin bolt-micro-journeys-nav-items-wrapper() {
  padding-left: var(--bolt-spacing-x-small);
}
