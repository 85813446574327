@use 'sass:math';
@use '@bolt/core' as core;
@use './nav-shared' as nav-shared;

bolt-interactive-step {
  // Override generic styles from _generic-shared.scss
  margin-bottom: 0 !important;
  padding-left: var(--bolt-spacing-x-small);

  > bolt-animate {
    display: block;
  }
}

.c-bolt-interactive-step {
  $nav-circle-size: 12px;
  $nav-circle-size--active: 14px;

  position: relative;
  max-width: 100vw;
  text-align: left;

  &__title {
    @include nav-shared.bolt-micro-journeys-nav-title;

    @include core.bolt-mq(medium) {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }

    .c-bolt-interactive-step--active & {
      @include nav-shared.bolt-micro-journeys-nav-title($active: true);
    }

    // The nav dot on mobile
    &:before {
      @include nav-shared.bolt-micro-journeys-nav-dot;

      .c-bolt-interactive-step--active & {
        @include nav-shared.bolt-micro-journeys-nav-dot($active: true);
      }
    }

    // The nav line on mobile
    &:after {
      @include nav-shared.bolt-micro-journeys-nav-line;

      .c-bolt-interactive-step--last & {
        display: none;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: -9999px;
    padding-right: var(--bolt-spacing-x-small);
    padding-bottom: var(--bolt-spacing-y-medium);
    padding-left: var(--bolt-spacing-x-small);

    @include core.bolt-mq(medium) {
      padding-right: var(--bolt-spacing-x-medium);
    }

    .c-bolt-interactive-step--active & {
      position: static;
    }

    .c-bolt-interactive-step--last & {
      width: calc(100% + #{$nav-circle-size / 2});
      margin-left: - math.div($nav-circle-size, 2);
      padding-left: 0;
    }
  }

  // for IE
  &__top-slot,
  &__bottom-slot,
  bolt-animate[slot='top'],
  bolt-animate[slot='bottom'] {
    width: 100%;
  }

  &__body-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;

    @include core.bolt-mq(medium) {
      max-width: 700px;
    }

    // IE11 bug: Column flex items set to align-items: center overflow their container ~ https://github.com/philipwalton/flexbugs#flexbug-2
    > * {
      max-width: 100%;
    }
  }
}

// Register Custom Block Element
@include core.bolt-custom-element('bolt-interactive-step', block, medium);
