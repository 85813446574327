@forward "src/character";
@forward "src/connection";
@forward "src/cta";
@forward 'src/interactive-step';
@forward 'src/interactive-pathway';
@forward 'src/interactive-pathways';
@forward 'src/one-character-layout';
@forward 'src/status-dialogue-bar';
@forward 'src/two-character-layout';
@forward 'src/bolt-svg-animations/svg-animations';

bolt-character {
  display: inline-block;
}

bolt-interactive-pathway,
bolt-interactive-pathways {
  display: block;
}

bolt-interactive-pathways {
  // Set to 1 in bolt-interactive-pathways render
  opacity: 0;
}

.c-bolt-micro-journeys-flex-aspect {
  display: block;
  max-width: 100%;
}

.c-bolt-micro-journeys-flex-aspect-full {
  display: block;
  width: 100%;
}
