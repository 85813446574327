.c-bolt-svg-animations {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 100%;
  background: transparent;

  > svg {
    vertical-align: middle; // removes mysterious margin bottom https://stackoverflow.com/a/10844318
  }
}

.c-bolt-svg-animations__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
