@import '@bolt/core';

bolt-two-character-layout {
  bolt-animate[slot^='character--'] {
    display: block;
    flex: 1 0 100%;
    width: 100%;
  }
}

.c-bolt-two-character-layout {
  position: relative;

  &__character {
    position: relative;
  }

  &__initial {
    opacity: 0;
  }

  &__character-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    max-width: 100%;

    @include bolt-mq(xsmall) {
      max-width: 400px;
    }

    @include bolt-mq(small) {
      max-width: 500px;
    }

    @include bolt-mq(large) {
      max-width: 700px;
    }
  }

  &__character {
    display: flex;
    flex: 0 1 49%;
    justify-content: space-between;
    align-items: center;

    // IE 11 collapses flexible width, but we need flex for alignment.
    @include bolt-ie11-only {
      justify-content: flex-start;
      width: 250px !important;
    }

    &--left,
    &--right {
      position: static;
      z-index: 1;
    }

    &--connection {
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
    }
  }
}
