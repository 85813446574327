@forward '@bolt/core';

html bolt-one-character-layout {
  display: flex;
  justify-content: center;
}

.c-bolt-one-character-layout {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
